.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 80%;
    padding: 20px 10%;
    background: transparent;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 100;
}

.logo {
    font-size: 32px;
    color: aliceblue;
    font-weight: 700;
}

.navbar a {
    position: relative;
    font-size: 18px;
    color: aliceblue;
    font-weight: 500;
    margin-left: 40px;

}

.navbar a::before {
    content: '';
    position: absolute;
    top: 100%;
    width: 100;
    left: 0;
    width: 0;
    height: 2px;
    background-color: aliceblue;
    transform: .3s;
}

.navbar a:hover::before {
    width: 100%;
}